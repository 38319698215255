<template>
  <fragment>
    <div v-if="!success" class="alert alert-danger">
      <p>
        An error occurred unsubscribing your email from our mailing list. Please complete the recaptcha challenge below and try again.
      </p>
    </div>
    <div v-if="!isError">
      <p>To unsubscribe from our mailing list, please click &quot;Unsubscribe&quot;</p>
      <p>Emails generated in the queue prior to the unsubscribe action are unable to be stopped.</p>
      <p>We appreciate your patience.</p>
      <p class="text-center">
        <a v-bind:href="apiBaseUrl+'/api/v1/user/emailoptout?token='+token+'&captcha='+this.captchaResponse" class="btn btn-success" v-bind:class="{disabled: this.captchaKey =='NOTSET' || this.captchaResponse==''}">
          Unsubscribe
        </a>
      </p>
      <p class="captcha-container text-center" v-if="this.captchaKey != 'NOTSET' && this.captchaKey != 'NA'">
        <VueRecaptcha v-bind:sitekey="this.captchaKey" v-on:verify="this.verifyCaptcha" ref="vueRecaptcha"></VueRecaptcha>
      </p>
    </div>

    <div v-if="isError" class="alert alert-danger">
      <p>Wrong Link</p>
      <p>
        You have clicked on an invalid link. Please make sure that you have typed the link correctly. If are copying this link from a mail reader please ensure that
        you have copied all the lines in the link.
      </p>
    </div>
  </fragment>
</template>

<script>
import {VueRecaptcha} from 'vue-recaptcha';
import {ClientSettingService} from '@/services/';
export default {
  components:{
    VueRecaptcha
  },
  name: "email-unsubscribe-confirm",
  data: function() {
    return {
      isError: false,
      apiBaseUrl: process.env.VUE_APP_API_URL,
      token: this.$route.params.token,
      captchaResponse: "",
      captchaKey: 'NOTSET',
      success: true
    };
  },
  methods: {
    verifyCaptcha: function(response){
      this.captchaResponse = response;
    }
  },
  async beforeMount(){
    await ClientSettingService.getRecaptchaKey().then((result) => {
      this.captchaKey = result.data.value;
    }).catch(()=>{
      this.captchaKey = 'NA';
      this.captchaResponse = 'NA';
    });
  },
  mounted(){
    if(this.$route.query.success=='false'){
      this.success = false;
    }
  }
};
</script>
